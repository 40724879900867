import { extendTheme } from "@mui/joy/styles";

declare module "@mui/joy/styles" {
  // No custom tokens found, you can skip the theme augmentation.
}


const theme = extendTheme({
  "colorSchemes": {
    "light": {
      "palette": {}
    },
    "dark": {
      "palette": {
        "primary": {
          "50": "#fff7ed",
          "100": "#ffedd5",
          "200": "#fed7aa",
          "300": "#fdba74",
          "400": "#fb923c",
          "500": "#f97316",
          "600": "#ea580c",
          "700": "#c2410c",
          "800": "#9a3412",
          "900": "#7c2d12"
        }
      }
    }
  }
})

// const theme = extendTheme({
//   colorSchemes: {
//     light: {
//       palette: {},
//     },
//     dark: {
//       palette: {
//         primary: {
//           "50": "#fff3e0",
//           "100": "#ffe0b2",
//           "200": "#ffcc80",
//           "300": "#ffb74d",
//           "400": "#ffa726",
//           "500": "#ff9800",
//           "600": "#fb8c00",
//           "700": "#f57c00",
//           "800": "#ef6c00",
//           "900": "#e65100",
//         },
//       },
//     },
//   },
// });

export default theme;
