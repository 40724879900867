import "./App.css";

import NewsContainer from "./NewsContainer";
import { Navigate, Route, Routes} from "react-router-dom";

import { CssVarsProvider } from "@mui/joy/styles";

// import { ThemeProvider, createTheme } from "@mui/material/styles";
// import CssBaseline from "@mui/material/CssBaseline";
// import SpeechSelectionDialog from "./SpeechSelectionDialog.tsx";
import { CookiesProvider } from "react-cookie";
import theme from "./theme";
import ErrorPage from "./ErrorPage";
import UserSettings from "./UserSettings";
import { forwardRef } from "react";

// const router = createBrowserRouter([
//   {
//     path: "*",
//     element: <Navigate to="/news/" replace></Navigate>,
//     errorElement: <ErrorPage />,

//   },
//   {
//     path: "/news/:page?",
//     element: <NewsContainer></NewsContainer>,
//     errorElement: <ErrorPage />,
//   },
//   {
//     path: "/settings",
//     element: <UserSettings></UserSettings>,
//   },
// ]);

// const theme = extendTheme({ cssVarPrefix: "news" });

function App() {
  return (
    <CookiesProvider>
      <CssVarsProvider defaultMode="dark" theme={theme}>
        <Routes>
          <Route path="/news/:page?" element={<NewsContainer />} errorElement={ <ErrorPage />} />
          <Route path="/settings" element={<UserSettings />} />
          <Route path="*" element={<Navigate to="/news/general" replace />} />
        </Routes>
        {/* <RouterProvider router={router} /> */}
      </CssVarsProvider>
    </CookiesProvider>
  );
}

export default App;
