import Typewriter from "typewriter-effect";
import TextToSpeech from "./TextToSpeech";
import { useQuery, gql } from "@apollo/client";
import { Grid, LinearProgress, Typography } from "@mui/joy";
import Divider from "@mui/joy/Divider";
// import markdownStyle from "./markdown-styles.module.css";

const GET_NEWS_SUMMARY = gql`
  query news($newsParams: NewsParams) {
    news(newsParams: $newsParams) {
      category
      searchQuery
      content
      links
    }
  }
`;

// export interface NewsResult {
//   category: String;
//   searchQuery: String;
//   content: String;
//   links: String;
// }

interface NewsProps {
  category: string;
  offlineMode: boolean;
  summaryLength: number; //0-2 
  childToParent: (data: string) => void;
}

function NewsComponent(props: NewsProps) {
  const cat = props.category;
  const callback = props.childToParent;
  const summaryLength = props.summaryLength;

  const loadMessages: string[] = [
    "Discovering new ways of making you wait.",
    "Your time is very important to us. Please wait while we ignore you.",
    "Still faster than Windows update.",
    "We are not liable for any broken screens as a result of waiting.",
    "Bored of slow loading spinner?, buy more RAM!",
    "Kindly hold on until I finish a cup of coffee.",
    "We will be back in 1/0 minutes.",
    "Why don't you order a sandwich?",
    "Don't panic, Just count to infinite.",
    "Please wait, Your PC is not a superman!",
    "Just count to 10",
    "Why so serious?",
    "It's not you. It's me.",
    "Counting backwards from Infinity",
    "Adjusting flux capacitor...",
    "Don't break your screen yet!",
    "Patience. Toby's thinking ..................................................................... ",
    "Hold on. I've got to read all this news first to write a good summery for you ..................................................................... Still reding. But getting better at it :-)    ",
  ];

  const { loading, error, data } = useQuery(GET_NEWS_SUMMARY, {
    variables: {
      newsParams: {
        category: cat,
        searchQuery: "",
        summaryLength: summaryLength,
      },
    },
    onCompleted: () => {
      callback(data?.news?.links);
    },
  });

  if (loading) callback("");
  if (error) return <p>Error : {error.message}</p>;

  function getRandomInt(max: number) {
    return Math.floor(Math.random() * max);
  }

  return (
    <div>
      <Grid container spacing={2} sx={{ flexGrow: 1 }}>
        <Grid sx={{ paddingTop: "20px" }}>
          <TextToSpeech text={data?.news?.content}></TextToSpeech>
        </Grid>
        <Grid xs={10}>
          <Grid container spacing={0} sx={{ flexGrow: 1 }}>
            <Grid xs={12} xl={12}>
              <Typography level="h4">
                <b>All The Exciting News Unfolding Around the World {cat != "general" ? " - " + cat.toUpperCase() : ""}</b>
              </Typography>
            </Grid>
            <Grid xs={12} xl={12}>
              <Typography level="body-lg">Your Daily Report - {new Date().toLocaleDateString()}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Divider sx={{ marginTop: "20px" }}></Divider>
      {loading ? (
        <div>
          <LinearProgress size="sm"/>
          <Typewriter
            options={{
              strings: [loadMessages[getRandomInt(loadMessages.length)]],
              autoStart: true,
              loop: false,
              cursor: "_",
              deleteSpeed: 100000,
              delay: 70,
            }}
          />
        </div>
      ) : (
        <div>
          <Typewriter
            options={{
              strings: [data.news.content],
              autoStart: true,
              loop: false,
              cursor: "_",
              deleteSpeed: 100000,
              delay: 0,
            }}
          />
        </div>
      )}
    </div>
  );
}

export default NewsComponent;
