import React, { useState } from "react";
import { useCookies } from "react-cookie";
import strip from "strip-markdown";
import { remark } from "remark";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import IconButton from "@mui/joy/IconButton";
// import fs from "fs";
// import path from "path";
// import OpenAI from "openai";

interface TextToSpeechProps {
  text: string;
}

function TextToSpeech(props: TextToSpeechProps) {
  const ButtonTextStart: string = "Read it to me";
  const ButtonTextStop: string = "Stop Reading";
  const [buttonText, setButtonText] = useState(ButtonTextStart);

  const [cookies] = useCookies(["voice"]);
  // const client = new OpenAI({
  //   // apiKey: "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",
  //   apiKey: "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",
  // });

  // const cleanedText = unified().use(strip).processSync(props.text);
  const cleanedText = String(remark().use(strip).processSync(props.text));
  console.log(cleanedText);

  const msg = new SpeechSynthesisUtterance();
  msg.text = cleanedText;

  console.log(msg.text);


  // async function textToSpeech(msg: String) {

  //   const speechFile = path.resolve("./speech.mp3");

  // // const textToSpeech = asynch (msg: String) => {
  //   const mp3 = await client.audio.speech.create({
  //     model: "tts-1",
  //     voice: "nova",
  //     input: "Today is a wonderful day to build something people love!",
  //     speed: 1.0,
  //   });

  //   const buffer = Buffer.from(await mp3.arrayBuffer());
  //   await fs.promises.writeFile(filename, buffer);

  //   // const response = await client.audio.speech.create(
      
      
      
  //   //   (model = "tts-12"), (voice = "alloy"), (input = "Hello world! This is a streaming test."));

  //   response.stream_to_file("output.mp3");
  // };

  const speechHandler = (msg: SpeechSynthesisUtterance) => {
    if (window.speechSynthesis.speaking) {
      window.speechSynthesis.cancel();
      setButtonText(ButtonTextStart);
    } else {
      if (cookies.voice !== undefined) {
        let voiceToUse = window.speechSynthesis
          .getVoices()
          .find((voice) => voice.name === cookies.voice);
        console.log("Setting voice: " + voiceToUse);
        if (voiceToUse) {
          msg.voice = voiceToUse;
        }
      }

      window.speechSynthesis.speak(msg);
      setButtonText(ButtonTextStop);
    }
   };

  return (
    <IconButton
      size="sm"
      variant="solid"
      color="primary"
      aria-label="read-text"
      onClick={() => speechHandler(msg)}
      sx={{
        verticalAlign: "center",
        alignContent: "center",
        borderRadius: "15px",
      }}
    >
      <VolumeUpIcon></VolumeUpIcon>
    </IconButton>
    // <IconButton onClick={() => speechHandler(msg)}>
    //   {/* {buttonText} */}
    //   <VolumeUpIcon></VolumeUpIcon>
    // </IconButton>
  );
}

export default TextToSpeech;
