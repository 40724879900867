import { SyntheticEvent } from "react";
import Button from "@mui/joy/Button";
import Typography from "@mui/joy/Typography";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";

// import { TransitionProps } from "@mui/joy/transitions";
import {
  Box,
  FormControl,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Sheet,
  Slider,
  Stack,
  styled,
} from "@mui/joy";

import { useCookies } from "react-cookie";
const SystemDefaultVoiceString = "System Default";

function UserSettings() {
  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies(["summaryLength"]);

  const marks = [
    {
      value: 0,
      label: "Short",
    },
    {
      value: 1,
      label: "Medium",
    },
    {
      value: 2,
      label: "Long",
    },
  ];

  function setSummaryLength(
    event: Event | SyntheticEvent<Element, Event>,
    value: number | number[]
  ): void {
    console.log("Set Summary Length to: " + value);
    setCookie("summaryLength", value, { path: "/", maxAge: 345600 }); // Expiry set to 400 days, which is the max age for Chrome.
  }

  return (
    <Box
      sx={{
        // borderBottom: 1,
        // borderColor: "divider",
        minWidth: 300,
        maxWidth: 600,
        margin: "auto",
      }}
    >
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="left"
        spacing={1}
        sx={{ marginTop: "28px", paddingBottom: "20px" }}
      >
        <Stack
          direction="row"
          justifyContent="left"
          alignItems="left"
          spacing={1}
        >
          <Button
            variant="outlined"
            startDecorator={<ArrowBackIcon />}
            onClick={() => {
              navigate("/");
            }}
          >
            Back
          </Button>
        </Stack>

        <Stack direction="row" sx={{ minWidth: 0, paddingTop: "10Px" }}>
          <Typography
            color="neutral"
            fontSize="lg"
            fontWeight="lg"
            width={350}
            sx={{ marginTop: "10px" }}
          >
            Summary Length
          </Typography>
          <Slider
            aria-label="Summary Length"
            defaultValue={cookies.summaryLength}
            step={1}
            max={2}
            valueLabelDisplay="off"
            marks={marks}
            onChangeCommitted={setSummaryLength}
          />
        </Stack>
      </Stack>
    </Box>
  );
}

export default UserSettings;
