import React, { useState } from "react";
import Tabs from "@mui/joy/Tabs";
import Typography from "@mui/joy/Typography";
import Box from "@mui/joy/Box";
import NewsComponent from "./NewsComponent";
import NewsSlideshow from "./NewsSliderComponent";
import Stack from "@mui/joy/Stack";
import IconButton from "@mui/joy/IconButton";
import ImageIcon from "@mui/icons-material/Image";
import TabList from "@mui/joy/TabList";
import Tab from "@mui/joy/Tab";
import LinkIcon from "@mui/icons-material/Link";
import SettingsIcon from "@mui/icons-material/Settings";

import { Grid, Sheet } from "@mui/joy";
import LinksComponent from "./LinksComponent";
import { useNavigate, useParams, redirectDocument  } from "react-router-dom";
import { useCookies } from "react-cookie";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`vertical-tabpanel-${index}`} aria-labelledby={`vertical-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ p: 0, paddingTop: "25px" }}>
          {children}
        </Box>
      )}
    </div>
  );
}


const indexToTabName: { [index: number]: string } = {
  0: "general",
  1: "sports",
  2: "business",
  3: "science",
  4: "technology",
  5: "entertainment",
  6: "health",
};

const tabNameToIndex: { [index: string]: number } = {
  general: 0,
  sports: 1,
  business: 2,
  science: 3,
  technology: 4,
  entertainment: 5,
  health: 6,
};


function NewsContainer() {
  const navigate = useNavigate();
  let { page } = useParams();
  console.log("router page: " + page);

  if (page === undefined || tabNameToIndex[page!] === undefined) {
    page=indexToTabName[0];
    console.log("router page rewritten to: " + page);
    // return redirectDocument("/news/" + page);

    // return redirect("/");
    // navigate("/", {preventScrollReset: true, replace: true});
  }


  const [showImages, setShowImages] = React.useState(false);
  const [showLinks, setShowLinks] = React.useState(false);
  const [linkData, setLinkData] = useState("");

  const [newsCategory, setNewsCategory] = React.useState(page!);
  const offline: boolean = false;

  const newsContainerLinkData = (newsComponentLinkData: string) => {
    console.log("newsContainerLinkData: " + newsComponentLinkData);
    setLinkData(newsComponentLinkData);
  };


  const [cookies] = useCookies(["summaryLength"]);

  const [value, setValue] = React.useState(tabNameToIndex[page!]);

  const handleChange = (event: React.SyntheticEvent | null, value: number | string | null) => {

    if (typeof value === "number") {
      try {
        setNewsCategory(indexToTabName[value]);
        history.pushState("", "", `/news/${indexToTabName[value]}`);
        setValue(value);
      } catch(error) {
        setNewsCategory(indexToTabName[0]);
        setValue(0);
      }
    } else {
      setNewsCategory(indexToTabName[0]);
      setValue(0);
    }
  };

  return (
    <div>
      <Box
        sx={{
          // borderBottom: 1,
          // borderColor: "divider",
          minWidth: 300,
          maxWidth: 790,
          margin: "auto",
        }}
      >
        <Stack direction="column" justifyContent="center" alignItems="left" spacing={1} sx={{ marginTop: "28px" }}>
          <Stack direction="row" justifyContent="space-between" alignItems="left" spacing={0}>
            <Stack direction="row" justifyContent="left" alignItems="left" spacing={0}>
              <Grid container spacing={0} width={190}>
                <Grid xs={12}>
                  <img className="newsgptLogo" src="/newsgpt.png"></img>
                </Grid>
                <Grid>
                  <Typography level="body-sm">by aRobotMadeMe</Typography>
                </Grid>
              </Grid>
              <Sheet
                // variant="outlined"
                sx={{
                  borderRadius: "md",
                  display: "flex",
                  p: 0,
                  bgcolor: "transparent",
                }}
              >
                <IconButton
                  onClick={() => {
                    setShowImages((prev) => !prev);
                    setShowLinks(false);
                  }}
                >
                  <ImageIcon></ImageIcon>
                </IconButton>
                <IconButton
                  onClick={() => {
                    setShowLinks((prev) => !prev);
                    setShowImages(false);
                  }}
                >
                  <LinkIcon></LinkIcon>
                </IconButton>
              </Sheet>
            </Stack>
            <Sheet
              // variant="outlined"
              sx={{
                borderRadius: "md",
                display: "flex",
                p: 0,
                marginRight: "20px",
                bgcolor: "transparent",
              }}
            >
              <IconButton
                sx={{ marginRight: "0px" }}
                onClick={() => {
                  navigate("/settings");
                }}
              >
                <SettingsIcon />
              </IconButton>
            </Sheet>
          </Stack>

          {showImages && <NewsSlideshow category={newsCategory} offlineMode={offline}></NewsSlideshow>}
          {showLinks && (
            <div>
              <LinksComponent markdownText={linkData}></LinksComponent>
            </div>
          )}
        </Stack>
      </Box>

      <Box
        sx={{
          minWidth: 300,
          maxWidth: 790,
          margin: "auto",
          marginTop: "30px",
        }}
      >
        <Tabs
          value={value}
          size="md"
          onChange={handleChange}
          // onChange={handleChange}
          aria-label="Vertical tabs"
          sx={{
            bgcolor: "transparent",
          }}
        >
          <TabList
            color="neutral"
            disableUnderline
            sx={{
              overflow: "auto",
              scrollSnapType: "x mandatory",
              "&::-webkit-scrollbar": { display: "none" },
              borderRadius: "15px",
              border: "0px",
              p: 0,
            }}
          >
            <Tab variant={value === 0 ? "outlined" : "plain"} disableIndicator sx={{ flex: "none", scrollSnapAlign: "start" }}>
              All
            </Tab>
            <Tab variant={value === 1 ? "outlined" : "plain"} disableIndicator sx={{ flex: "none", scrollSnapAlign: "start" }}>
              Sports
            </Tab>
            <Tab variant={value === 2 ? "outlined" : "plain"} disableIndicator sx={{ flex: "none", scrollSnapAlign: "start" }}>
              Business
            </Tab>
            <Tab variant={value === 3 ? "outlined" : "plain"} disableIndicator sx={{ flex: "none", scrollSnapAlign: "start" }}>
              Science
            </Tab>
            <Tab variant={value === 4 ? "outlined" : "plain"} disableIndicator sx={{ flex: "none", scrollSnapAlign: "start" }}>
              Technology
            </Tab>
            <Tab variant={value === 5 ? "outlined" : "plain"} disableIndicator sx={{ flex: "none", scrollSnapAlign: "start" }}>
              Entertainment
            </Tab>
            <Tab variant={value === 6 ? "outlined" : "plain"} disableIndicator sx={{ flex: "none", scrollSnapAlign: "start" }}>
              Health
            </Tab>
          </TabList>
        </Tabs>
        <TabPanel value={value} index={0}>
          <NewsComponent
            category={newsCategory}
            offlineMode={offline}
            childToParent={newsContainerLinkData}
            summaryLength={Number(cookies.summaryLength)}
          ></NewsComponent>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <NewsComponent
            category={newsCategory}
            offlineMode={offline}
            childToParent={newsContainerLinkData}
            summaryLength={Number(cookies.summaryLength)}
          ></NewsComponent>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <NewsComponent
            category={newsCategory}
            offlineMode={offline}
            childToParent={newsContainerLinkData}
            summaryLength={Number(cookies.summaryLength)}
          ></NewsComponent>
        </TabPanel>
        <TabPanel value={value} index={3}>
          <NewsComponent
            category={newsCategory}
            offlineMode={offline}
            childToParent={newsContainerLinkData}
            summaryLength={Number(cookies.summaryLength)}
          ></NewsComponent>
        </TabPanel>
        <TabPanel value={value} index={4}>
          <NewsComponent
            category={newsCategory}
            offlineMode={offline}
            childToParent={newsContainerLinkData}
            summaryLength={Number(cookies.summaryLength)}
          ></NewsComponent>
        </TabPanel>
        <TabPanel value={value} index={5}>
          <NewsComponent
            category={newsCategory}
            offlineMode={offline}
            childToParent={newsContainerLinkData}
            summaryLength={Number(cookies.summaryLength)}
          ></NewsComponent>
        </TabPanel>
        <TabPanel value={value} index={6}>
          <NewsComponent
            category={newsCategory}
            offlineMode={offline}
            childToParent={newsContainerLinkData}
            summaryLength={cookies.summaryLength as number}
          ></NewsComponent>
        </TabPanel>
      </Box>
    </div>
  );
}

export default NewsContainer;
