import React from "react";
import AwesomeSlider from "react-awesome-slider";
import "react-awesome-slider/dist/styles.css";
import withAutoplay from "react-awesome-slider/dist/autoplay";
// import dummyArticles from "./dummydata/dummyArticles.json";
import { useQuery, gql } from '@apollo/client';

const GET_NEWS_HEADLINES = gql`
  query Query {
  newsHeadlines {
    status
    totalResults
    articles {
      description
      title
      url
      urlToImage
      source {
        id
        name
      }
    }
  }
}
`;

interface ArticleType {
  url: string;
  urlToImage: string;
  title:string;
}

interface NewsSlideshowProps {
  category: string;
  offlineMode:boolean;
}

function NewsSlideshow(props: NewsSlideshowProps) {

  const { loading, error, data } = useQuery(GET_NEWS_HEADLINES);
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error : {error.message}</p>;
   
  const AutoplaySlider = withAutoplay(AwesomeSlider);

  return (
    <AutoplaySlider
      style={{ maxWidth: "550px", height: "auto", margin: 'auto' }}
      play={true}
      cancelOnInteraction={false}
      interval={5000}
      bullets={false}
    >
      
      {data.newsHeadlines.articles && data.newsHeadlines.articles.map((article:ArticleType) => (
        <div key={article.url}>
          <img src={article.urlToImage} alt="" />
          <div className="caption">
            <a href={article.url} target="_blank" rel="noopener noreferrer">
              {article.title}
            </a>
          </div>
        </div>
      ))}
    </AutoplaySlider>
  );
}

export default NewsSlideshow;
