import React, { useEffect } from "react";
import "react-awesome-slider/dist/styles.css";
import ReactMarkdown from "react-markdown";
import markdownStyle from "./markdown-styles.module.css";

interface LinksComponentProps {
  markdownText: string;
}

function LinksComponent(props: LinksComponentProps) {

  useEffect(() => {
    // code to run after render goes here
    Array.from(document.links)
    .filter(link => link.hostname != window.location.hostname)
    .forEach(link => link.target = '_blank');
  });
  return (
    <ReactMarkdown className={markdownStyle.reactMarkDown}>
      {
        props.markdownText
      }
              {/* "[Splashdown! NASA's SpaceX Crew-7 Finishes Mission, Returns to Earth - NASA](https://www.nasa.gov/news-release/splashdown-nasas-spacex-crew-7-finishes-mission-returns-to-earth/)" */}
    </ReactMarkdown>
  );
}

export default LinksComponent;
